import { Box, Button, CachedIcon, Grid, LoadingButton, Typography, styled } from "@enerbit/base";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import RemoveIcon from "@mui/icons-material/Remove";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { MissingHoursStatus, RestoreSchedulesStatuses } from "../../models/frontiers";
import { MISSING_HOURS_STATUS } from "../../const";
import { restoreSchedules } from "../../services";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { getRestoreSechedulesStatus } from "../../store/thunks/frontiers";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import moment from "moment";

interface Props {
  hours: Record<string, MissingHoursStatus>;
  dayKey: string;
  meterId: string;
}

const MissingHoursItem = ({ hours, dayKey, meterId }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const restoreStatus = useSelector((state: RootState) => state.frontiers.restoreScheduleStatus);

  const dispatch = useDispatch<AppDispatch>();
  const getIcon = (color: string, iconName: string) => {
    const STATUS_ICONS: Record<string, any> = {
      checkIcon: <CheckIcon sx={{ fontSize: "14px", color }} />,
      closeIcon: <CloseIcon sx={{ fontSize: "14px", color }} />,
      lineIcon: <RemoveIcon sx={{ fontSize: "14px", color }} />,
      priorityHighIcon: <PriorityHighIcon sx={{ fontSize: "14px", color }} />,
    };

    return STATUS_ICONS[iconName];
  };

  const calculateRestoreRange = () => {
    let minMissingHour = null;
    let maxMissingHour = null;

    for (const hour in hours) {
      if (hours[hour] === "MISSING") {
        if (!minMissingHour || hour < minMissingHour) {
          minMissingHour = hour;
        }

        if (!maxMissingHour || hour > maxMissingHour) {
          maxMissingHour = hour;
        }
      }
    }

    return { minMissingHour, maxMissingHour };
  };

  const restoreSechedulesData = async () => {
    const range = calculateRestoreRange();
    
    if (!range.minMissingHour || !range.maxMissingHour) return;
    setLoading(true);
    try {
      const since = moment(`${dayKey}T${range.minMissingHour}`).format("YYYY-MM-DDTHH:mm:ssZ");
      const until = moment(`${dayKey}T${range.maxMissingHour}`)
        .add("hour", 1)
        .format("YYYY-MM-DDTHH:mm:ssZ");

      await restoreSchedules(meterId, since, until, "8");
      //await dispatch(getRestoreSechedulesStatus(meterId));
      enqueueSnackbar("El comando de recuperación fue enviado con éxito.", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Error al enviar el comando de recuperación.", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container columnSpacing={2} mt={3}>
      <Grid item xs={1.3}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 2 }}>
          <Typography color={"#120C20"}>{dayKey.split("T")[0]}</Typography>
          <LoadingButton
            loading={loading}
            onClick={() => restoreSechedulesData()}
            disabled={restoreStatus.state === RestoreSchedulesStatuses.WAITING}
            sx={{
              width: "28px",
              minWidth: "unset !important",
              height: "28px",
              borderRadius: "8px !important",
              color: "white",
            }}
            variant='contained'
            color='success'>
            <CachedIcon />
          </LoadingButton>
        </Box>
      </Grid>
      {Object.keys(hours).map((key) => (
        <Grid item xs={0.43} key={key}>
          <StatusIndicator bgcolor={MISSING_HOURS_STATUS[hours[key]].bgcolor}>
            {getIcon(MISSING_HOURS_STATUS[hours[key]].color, MISSING_HOURS_STATUS[hours[key]].icon)}
          </StatusIndicator>
        </Grid>
      ))}
    </Grid>
  );
};

export default MissingHoursItem;

interface Status {
  bgcolor: string;
}
const StatusIndicator = styled("div")<Status>(({ bgcolor }) => ({
  width: "25px",
  height: "25px",
  backgroundColor: bgcolor,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "8px",
}));
