import {
  AdapterMoment,
  Box,
  Button,
  CircularProgress,
  DatePicker,
  FormControl,
  Grid,
  LocalizationProvider,
  SearchIcon,
  Typography,
} from "@enerbit/base";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  scales,
} from "chart.js";
import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { getMeterUsages } from "../../services";
import { ChartData, MeasuresModel } from "../../models/frontiers";
interface Props {
  measurementPointId: string;
}

import { formatDateRange } from "../../helpers";
import { USAGES_CHART_DATASETS } from "../../const";
import moment from "moment";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const UsagesChart = ({ measurementPointId }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [labels, setLabels] = useState<string[]>([]);
  const [chartData, setChartData] = useState<ChartData>({} as ChartData);
  const [since, setSince] = useState<string>("");
  const [until, setUntil] = useState<string>("");

  const getUsages = async () => {
    setLoading(true);
    try {
      const response = await getMeterUsages(measurementPointId, since, until);
      const parsedDates = response.map((item: any) => formatDateRange(item.start, item.end));
      setLabels(parsedDates);
      setChartData((prev) => {
        const nD = { ...prev };
        nD.active_energy_exported = response.map(
          (item: MeasuresModel) => item.active_energy_exported
        );
        nD.active_energy_imported = response.map(
          (item: MeasuresModel) => item.active_energy_imported
        );
        nD.reactive_energy_imported = response.map(
          (item: MeasuresModel) => item.reactive_energy_imported
        );
        nD.reactive_energy_exported = response.map(
          (item: MeasuresModel) => item.reactive_energy_exported
        );
        return nD;
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUsages();
  }, []);

  const options = {
    reponsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top" as const,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Fecha",
        },
      },
      y: {
        title: {
          display: true,
          text: "Energía (kWh)",
        },
      },
    },
  };

  const data = {
    labels,
    datasets: USAGES_CHART_DATASETS.map((dataset) => ({
      label: dataset.label,
      data: chartData[dataset.data],
      borderColor: dataset.borderColor,
      backgroundColor: dataset.backgroundColor,
    })),
  };

  return (
    <Box sx={{ width: "100%", height: "480px", bgcolor: "#fff", padding: "24px" }}>
      <Typography mb={1} color={"primary"} sx={{ fontWeight: 700, fontSize: "18px" }}>
        Consumos
      </Typography>
      <Grid mb={3} container spacing={3}>
        <Grid item md={6} xs={12}>
          <Typography
            sx={{
              paddingBottom: "10px",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "16px",
            }}>
            Fecha inicial
          </Typography>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                format='YYYY-MM-DD'
                value={moment(since)}
                onChange={(value: any) => {
                  setSince(value?.format("YYYY-MM-DD"));
                }}
                maxDate={moment()}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error: false,
                    sx: { "& fieldset": { borderRadius: "12px" } },
                  },
                }}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography
            sx={{
              paddingBottom: "10px",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "16px",
            }}>
            Fecha final
          </Typography>
          <Grid container spacing={3}>
            <Grid item flex={1}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    format='YYYY-MM-DD'
                    value={moment(until)}
                    onChange={(value: any) => {
                      setUntil(value?.format("YYYY-MM-DD"));
                    }}
                    maxDate={moment()}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: false,
                        sx: { "& fieldset": { borderRadius: "12px" } },
                      },
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item mt={0.5}>
              <Button onClick={getUsages} color='primary' variant='contained'>
                <SearchIcon />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {!loading && (
        <Box style={{ height: "300px" }}>
          <Line options={options} data={data} />
        </Box>
      )}
      {loading && (
        <Box my={4} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default UsagesChart;
