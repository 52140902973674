import { Box } from "@enerbit/base";
import React, { useState } from "react";
import EventsTable from "../EventsTable/EventsTable";
import { StyledTabs } from "../StyledTabs/StyledTabs";
import { StyledTab } from "../StyledTab/StyledTab";
import { TabPanel } from "../TabPanel/TabPanel";
import MissingHours from "../MissingHours/MissingHours";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface Props {
  measurementPointId: string;
  meterId: string;
}

const DetailTabs = ({ measurementPointId, meterId }: Props) => {
  const [value, setValue] = useState<number>(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <StyledTabs value={value} onChange={handleChange}>
          <StyledTab label={<Box>Lista de datos faltantes</Box>} {...a11yProps(0)} />
          <StyledTab label={<Box>Eventos</Box>} {...a11yProps(1)} />
        </StyledTabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Box mt={3}>
          <MissingHours measurementPointId={measurementPointId} meterId={meterId} />
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box mt={3}>
          <EventsTable />
        </Box>
      </TabPanel>
    </Box>
  );
};

export default DetailTabs;
