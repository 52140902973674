import { api } from "@enerbit/base";
import moment from "moment";
import { groupMissingHours, searchMissingHours } from "../helpers";
import {
  AssociatedMeters,
  MeasuresModel,
  MeterRelationshipResponse,
  MeterStatusModel,
  MissingHoursStatus,
} from "../models/frontiers";
import { ReporAttempsResponse, XMResultModel } from "../models/reports";

export const getMetersList = async (serial: string) => {
  const res = await api.get(
    `/meter-reports/meters-latest-reports/?intervals_hours=24&size=20&meter_serial=${serial}`
  );

  return res.data.items[0];
};

export const getMetersDetail = async (meterSerial: string) => {
  const res = await api.get(`/meters/meters/?serial=${meterSerial}`);

  return res.data.items[0];
};

export const getMeterRelationshipDevice = async (meterId: string) => {
  const relationships = await api.get<MeterRelationshipResponse>(
    `/electricity-supply-service/device/device-meters/relationship/meters/${meterId}/`
  );

  const deviceId = relationships.data.items[0].device_meter.id;

  return deviceId;
};

export const getAssociatedMeters = async (deviceId: string) => {
  const res = await api.get<AssociatedMeters[]>(
    `/verification/smartbits/associated-meters?device_id=${deviceId}`
  );

  return res.data;
};

export const getMeterUsages = async (measurementPointId: string, since?: string, until?: string) => {
  const tempSince = since
    ? `${since}T00:00+00:00`
    : `${moment().subtract(3, "days").format("YYYY-MM-DD")}T00:00+00:00`;
  const tempUntil = until ? `${until}T00:00+00:00` : `${moment().format("YYYY-MM-DD")}T00:00+00:00`;

  const res = await api.get(
    `/solar-fustigations/solar-schedules/solar-schedules?measurement_point_id=${measurementPointId}&since=${encodeURIComponent(
      tempSince
    )}&until=${encodeURIComponent(tempUntil)}&period_number=1&period_str=day`
  );

  return res.data;
};

export const getMeterSerial = async (id: string) => {
  const res = await api.get(`/meters/meters/${id}/`);

  return res.data.serial;
};

export const generateSicReport = async (date: string) => {
  const res = await api.post(
    `/electricity-supply-service/device/asic-report/since/${encodeURIComponent(
      date
    )}/until/${encodeURIComponent(date)}/generator/`
  );

  return res;
};

export const getMissingHours = async (measurementPointId: string, since: string, until: string) => {
  const res = await api.get(
    `/solar-fustigations/solar-schedules/solar-schedules?measurement_point_id=${measurementPointId}&since=${encodeURIComponent(
      since
    )}&until=${encodeURIComponent(until)}&period_number=1&period_str=hour`
  );


  const tempData = res.data.map((d: MeasuresModel) => ({
    ...d,
    time_start: moment(d.start).format("YYYY-MM-DDTHH:mm:ssZ"),
    time_end: moment(d.end).format("YYYY-MM-DDTHH:mm:ssZ"),
  }));

  const groupedDates = groupMissingHours(tempData);

  const data: Record<string, Record<string, MissingHoursStatus>> = {};

  Object.keys(groupedDates).forEach((key) => {
    const missingHours = searchMissingHours(groupedDates[key], key);
    data[key] = missingHours;
  });

  return { groupedByDates: groupedDates, missingHours: data };
};

export const refustigateMeter = async (date: string, measurementPointId: string[]) => {
  let dateRaw = new Date(date);
  //dateRaw.setHours(dateRaw.getHours() - 5);
  let isoString = dateRaw.toISOString().replace('Z', '-05:00');
  console.log(isoString);
  const res = await api.get(`/solar-fustigations/solar-refustigation/solar-regustigation?date=${
    encodeURIComponent(isoString)
  }&measurement_point_id=${
    measurementPointId
  }`);
  /* const res = await api.get("http://localhost:8000/solar-refustigation/solar-regustigation?date=2024-07-10T00%3A00%3A00.000-05%3A00&measurement_point_id=394742ae-7f9c-11ee-a047-000d3a0fc799") */
  console.log(res);

  return res;
};

export const restoreSchedules = async (measurementPointId: string, since: string, until: string, hours: string) => {
  
  const res = await api.get(
    `/solar-fustigations/solar-restore/solar-restore/?measurement_point_id=${measurementPointId}&start_date=${encodeURIComponent(
      since
    )}&end_date=${encodeURIComponent(
      until
    )}&hours=${encodeURIComponent(hours)}`
  );

  return res;
};

export const restoreSchedulesByDeviceName = async (
  meterId: string,
  since: string,
  until: string
) => {
  const deviceData = await api.get(
    `/electricity-supply-service/device/device-meters/meters/${meterId}/`
  );

  const deviceName = deviceData.data.device_meter.name;

  const res = await api.post(
    `/electricity-supply-service/device/devices-name-iothub/${deviceName}/meters/restore-schedules/?since=${encodeURIComponent(
      since
    )}&until=${encodeURIComponent(until)}`
  );

  return res;
};

export const getMeterStatus = async (meterId: string) => {
  const res = await api.get<MeterStatusModel>(
    `/electricity-supply-service/device/meters/${meterId}/relay-status/`
  );

  return res.data;
};

export const deleteRestoreActiveCommand = async (meterId: string) => {
  await api.delete(`/electricity-supply-service/device/meters/${meterId}/active-command-state/`);
};

export const sendXMReport = async (reportId: string) => {
  const res = await api.post<string>(`/electricity-supply-service/device/asic-report/${reportId}/xm-soap-service/`);
  return res.data
};

export const getReportAttemps = async (reportId: string) => {
  const res = await api.get<ReporAttempsResponse>(
    `/electricity-supply-service/device/reports/${reportId}/report-attempts/`
  );

  return res.data.items;
};

export const getReportState = async (processId: string) => {
  const res = await api.get<XMResultModel>(
    `/electricity-supply-service/device/asic-report/process-result/${processId}/xm-soap-service/`
  );

  return res.data;
};
