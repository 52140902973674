import { HashRouter, Route, Routes } from "react-router-dom";
import ReadingRecordsList from "../pages/ReadingRecordsList/ReadingRecordsList";
import ReadingRecordsDetail from "../pages/ReadingRecordsDetail/ReadingRecordsDetail";
import XMReportsDetail from "../pages/XMReportsDetail/XMReportsDetail";

const Router = () => {
  return (
    <HashRouter basename='/solar-reading-records'>
      <Routes>
        <Route path='/' element={<ReadingRecordsList />} />
        <Route path='detail/:measurementPointId' element={<ReadingRecordsDetail />} />
        <Route path='xm-report/:proccessId' element={<XMReportsDetail />} />
      </Routes>
    </HashRouter>
  );
};

export default Router;
