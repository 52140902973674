import { Box, CircularProgress, CustomAlert, Grid } from "@enerbit/base";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import "../../styles/index.css";
import LineChart from "../../components/UsagesChart/UsagesChart";
import SmartbitsFlow from "../../components/SmartbitsFlow/SmartbitsFlow";
import { MeterDetail } from "../../models/frontiers";
import { getMetersDetail } from "../../services";
import DetailTabs from "../../components/DetailTabs/DetailTabs";
import RefustigateModal from "../../components/RefustigateModal/RefustigateModal";
import RestoreSchedulesModal from "../../components/RestoreSchedulesModal/RestoreSchedulesModal";
import RestoreSecheduleStatus from "../../components/RestoreSecheduleStatus/RestoreSecheduleStatus";
import DetailHeader from "../../components/DetailHeader/DetailHeader";
import MeterDetailActions from "../../components/MeterDetailActions/MeterDetailActions";

const ReadingRecordsDetail = () => {
  const location = useLocation();
  const { measurementPointId } = useParams();

  const [param, setParam] = useState<string>("");
  const [meterDetail, setMeterDetail] = useState<MeterDetail>();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [refustigateModal, setRefustigateModal] = useState<boolean>(false);
  const [restoreDataModal, setRestoreDataModal] = useState<boolean>(false);

  /* const getDetail = async () => {
    if (!measurementPointId) return;

    setParam(measurementPointId);
    setLoading(true);
    setError("");

    try {
      const detail = await getMetersDetail(measurementPointId);
      setMeterDetail(detail);
    } catch (error: any) {
      if (error.response.status === 404) {
        setError("No se ha encontrado información sobre este medidor");
      } else {
        setError("Error al consultar el detalle de este medidor");
      }
    } finally {
      setLoading(false);
    }
  }; */

  const openRefustigateModal = () => {
    setRefustigateModal(true);
  };
  const openRestoreDateModal = () => {
    setRestoreDataModal(true);
  };

  /* useEffect(() => {
    getDetail();
  }, [location.pathname]); */

  return (
    <>
      {/* {!error && !loading && meterDetail && <RestoreSecheduleStatus meterId={meterDetail.id} />} */}
      <Box>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <DetailHeader measurementPointId={param} />
        </Box>
        { !error && !loading }
          { <MeterDetailActions
            openRefustigateModal={openRefustigateModal}
            openRestoreDataModal={openRestoreDateModal}
          /> }
        
      </Box>
      {!loading && measurementPointId && !error && (
        <>
          <Grid mt={3} container columnSpacing={2}>
            {/* <Grid item xs={6}>
              <SmartbitsFlow meterId={meterDetail.id} meterSerial={meterDetail.serial} />
            </Grid> */}
            <Grid item xs={6}>
              <LineChart measurementPointId={measurementPointId} />
            </Grid>
          </Grid>
          <Box mt={3}>
            <DetailTabs measurementPointId={measurementPointId} meterId={measurementPointId} />
          </Box>
        </>
      )}
      {error && (
        <Box my={4}>
          <CustomAlert severity='error' text={error} onClose={() => setError("")} />
        </Box>
      )}
      {loading && (
        <Box my={4} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
      <RefustigateModal
        open={refustigateModal}
        handleClose={() => setRefustigateModal(false)}
        meterSerial={measurementPointId ?? ""}
        isBulk={false}
      />
      {/* {meterDetail && (
        <RestoreSchedulesModal
          meterId={meterDetail.id}
          handleClose={() => setRestoreDataModal(false)}
          open={restoreDataModal}
        />
      )} */}
    </>
  );
};

export default ReadingRecordsDetail;
